<template>
  <div class="myinvite">
    <div class="title" style="text-align: center;">优惠券</div>
    <div class="couponitem" v-for="(item,idx) in DataList" :key="idx">
      <div style="width: 80px; text-align: center; float: left; line-height: 25px;">
        <div v-if="item.coupon_type=='manjian'">
          <div style="color: #000; ">满 <span >{{item.coupon_money}}</span></div>
          <div style="font-size: 16px;">减 <span style="color: #0074D9; font-weight: bold;">{{item.coupon_value}}</span></div>
        </div>

        <div  v-if="item.coupon_type=='dazhe'" style="line-height: 50px; font-size: 20px; color: #0074D9;">
          {{item.coupon_value*10}}折
        </div>
        <div v-if="false">
          <div style="color: #000;">无门槛券</div>
          <div style="font-size: 16px;">抵扣<span style="color: #0074D9; font-weight: bold;">50</span></div>
        </div>

      </div>
      <div style="margin-left: 110px; min-height: 50px;position: relative;">

         <div style="font-size: 12px;padding-bottom: 30px; word-break: break-all;">{{item.coupon_desc}}</div>
        <div style="font-size: 12px; text-align: right;position: absolute; right: 0; bottom: 0; ">
          <span style="color: #fb290d;" v-if="item.coupon_status==3">已过期</span>
          <span style="color: #66cb04;" v-if="item.coupon_status==1">正常</span>
          <span style="color: #9d9d9d;" v-if="item.coupon_status==4">已作废</span>
          <span style="color: #0C85E1;" v-if="item.coupon_status==2">已使用</span>
        </div>
        <div style="font-size: 12px;position: absolute; left: 0; bottom: 0;">有效期至: {{item.coupon_canuse_date}}</div>
      </div>
      <div class="cardcircle_top"></div>
      <div class="cardcircle_bottom"></div>
    </div>

  </div>

</template>

<script>
  export default {
    data() {
      return {
        DataList:[]
      }
    },
    mounted() {

      document.title = "优惠券"
      this.getData();
    },
    methods: {
      getData() {
        this.$store.dispatch("GET", {
          url: "/api/wx/?action=mycoupon"
        }).then(res => {
          this.DataList = res.data.data.rows


        })
      },


    }
  }
</script>

<style scoped="scoped">
  .title {
    padding-top: 10px;
    font-size: 22px;
    color: #676767;
    text-align: center;
  }
  .couponitem {
    position: relative;
    width: 90%;
    margin: 0 auto;
    border: 1px solid #f6f6f6;
    padding: 10px;
    background: #fff;
    border-radius: 4px;
    margin-top: 10px;
  }

  .cardcircle_top {
    width: 12px;
    height: 12px;
    position: absolute;
    top: -6px;
    left: 100px;
    background: #ebf2fc;
    border-radius: 50%;
  }

  .cardcircle_bottom {
    width: 12px;
    height: 12px;
    position: absolute;
    bottom: -6px;
    left: 100px;
    background: #ebf2fc;
    border-radius: 50%;
  }
</style>
